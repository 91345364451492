import React from 'react';
import { MainBtn } from '../Buttons';

const Card = ({ CardOverlayComponent, ...props }) => {
  const overlayClasses = React.useMemo(
    () => `card-overlay${props.overlayActive === true ? ' active' : ''}`,
    [props.overlayActive]
  );
  const showCardCTA = React.useMemo(
    () => props.link && !props.overlayActive,
    [props.link, props.overlayActive]
  );
  return (
    <div
      style={props.style || null}
      className={`card ${props.className || ''}`}
    >
      {props.image ? (
        <div className="card-image-container">
          <img src={props.image.src} alt={props.image.alt} />
        </div>
      ) : null}
      <div className="card-content">
        <h2 className="card-title">{props.title}</h2>
        <p className="card-text">{props.children}</p>
      </div>
      {showCardCTA && (
        <MainBtn
          onClick={props.onLinkClick}
          href={props.link.href}
          text={props.link.text}
        />
      )}
      {!!CardOverlayComponent && (
        <div className={overlayClasses}>
          <CardOverlayComponent />
        </div>
      )}
    </div>
  );
};

export default Card;
