import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Locale, LocaleLabel } from '../../../../../../utils/enums/languages';

/**
 * Component rendering single language switch item
 * @param {Object} props
 * @param {string} props.currentLanguage
 * @param {string} props.language
 * @param {Function} props.onLanguageSwitch
 * @returns {JSX.Element}
 */
const NavbarLanguageSwitcherItem = ({
  currentLanguage,
  language,
  onLanguageSwitch
}) => (
  <span
    onClick={() => onLanguageSwitch(language)}
    className={classNames('control btn language', {
      'is-active': currentLanguage === language
    })}
  >
    {LocaleLabel[language]}
  </span>
);

NavbarLanguageSwitcherItem.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  language: PropTypes.oneOf(Object.values(Locale)).isRequired,
  onLanguageSwitch: PropTypes.func
};

export default NavbarLanguageSwitcherItem;
