import React from 'react';
import ReactGA from 'react-ga';

import { MainBtn } from '../../components/Buttons';
import { Container } from '../../components/Helpers';
import { SubpagesHero } from '../../components/Hero';

import { getBackToHomeLabel } from '../../utils/helpers/strings';

import not_found_data from '../../content/not_found_page.json';

import './NotFound.scss';

const NotFound = ({ currentLanguage }) => {
  const data = React.useMemo(
    () => not_found_data[currentLanguage.code],
    [currentLanguage]
  );

  React.useEffect(() => {
    const title = data.page_title.replace(/[^a-zA-Z0-9-_\s]/g, '');
    document.title = `${title} | Optifarm`;
    ReactGA.set({ title: document.title, page: document.title });
  }, [data]);

  return (
    <div className="not-found-page">
      <SubpagesHero hero_image="/images/hero-bg.png" />
      <Container>
        <div className="subpage-section">
          <img
            className="section-image"
            src="/images/empty.svg"
            alt="Not Found"
          />
          <h2 className="section-title is-centered">ooops!</h2>
          <h3 className="section-subtitle is-centered">{data.page_subtitle}</h3>
          <p className="section-excerpt">{data.page_text}</p>

          <div className="cta-container">
            <MainBtn href="/" text={getBackToHomeLabel(currentLanguage)} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
