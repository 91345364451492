import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentLanguageSelector } from '../../services/selectors/languageSelectors';

import NotFound from './NotFound';

const mapStateToProps = createStructuredSelector({
  currentLanguage: currentLanguageSelector
});

export default connect(mapStateToProps)(NotFound);
