import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { Container } from '../../components/Helpers';
import { SubpagesHero } from '../../components/Hero';
import { MapsSection, ContactInfoSection } from '../../components/Sections';
import { ContactForm } from '../../components/Forms';

import contact from '../../content/contact.json';

class Contact extends Component {
  componentDidMount() {
    let title = contact[this.props.currentLanguage].page_title.replace(
      /[^a-zA-Z0-9-_\s]/g,
      ''
    );
    document.title = `${title} | Optifarm`;
    ReactGA.set({ title: document.title, page: document.title });
  }
  render() {
    let contact_data = contact[this.props.currentLanguage];
    return (
      <div className="contact-subpage">
        <SubpagesHero data={contact_data} hero_image="/images/hero-bg.png" />
        <Container>
          <div className="subpage-main-content">
            <MapsSection data={contact_data.maps_info} isMarkerShown={false} />
            <ContactInfoSection data={contact_data.sections.contact_info} />
            <ContactForm data={contact_data.sections.contact_form} />
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.localize).code
});

export default connect(mapStateToProps)(Contact);
