import React, { Component } from 'react';
import { Container, Columns } from '../Helpers';
import * as Scroll from 'react-scroll';
import ContactInfoItem from './ContactInfoItem';

import { AppStoreIcon } from '../Icons';

import logo from '../../logo.svg';

class Footer extends Component {
  handleScrollTop(e) {
    Scroll.animateScroll.scrollToTop();
  }
  render() {
    const { data } = this.props;
    const { contact_info } = data;
    return (
      <footer>
        <div
          onClick={this.handleScrollTop.bind(this)}
          className="scroll-top is-hidden-desktop"
        >
          <i className="fas fa-angle-up"></i>
        </div>
        <Container>
          <Columns>
            <div className="column is-12 footer-left">
              <Columns>
                <div className="column is-half">
                  <img src={logo} alt="Optifarm" className="footer-logo" />
                </div>
                <div className="column is-half v-align-center h-align-end">
                  <div className="footer--badges-container">
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://play.google.com/store/apps/details?id=com.optifarm.optifarmb2c&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                      <img
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/optifarm-smart/id1506822995"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="app-link"
                    >
                      <AppStoreIcon size={150} />
                    </a>
                  </div>
                </div>
              </Columns>
              <div className="footer-contact-info-container">
                {contact_info.map((info, i) => (
                  <ContactInfoItem icon={info.icon} text={info.text} key={i} />
                ))}
              </div>
            </div>
          </Columns>
          <div className="footer-legal-info">
            {data.legal_notice} 2018 - {new Date().getFullYear()} •{' '}
            {data.company_legal_name}
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
