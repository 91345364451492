import React, { Component } from 'react';

class HowItWorksCard extends Component {
  render() {
    let data = this.props.data;
    return (
      <div className="card how-it-works-card">
        <div className="card-content">
          <h4 className="card-title">{data.title}</h4>
          <p className="card-text">{data.text}</p>
        </div>
        <img src={data.icon} alt={data.title} className="card-icon" />
      </div>
    );
  }
}

export default HowItWorksCard;
