import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import qs from 'qs';

import { currentLanguageCodeSelector } from '../../services/selectors/languageSelectors';

import { Container, Columns } from '../Helpers';

import { InputElement, TextareaElement, SuccessMessage } from './FormHelpers';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_submitted: false,
      is_loading: false,
      has_errors: false,
      success_message: '',
      data: {}
    };
  }

  handleInputChange(e) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [e.target.name.toLowerCase()]: e.target.value
      }
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({
      ...this.state,
      has_errors: false,
      is_loading: true
    });

    try {
      const { data, status } = await axios.post(
        'https://functions.optifarm.net/mail/sendmail',
        this.state.data,
        {
          headers: {
            'x-api-key': 'KtbrN2amov9Kb7gliR1eM8jbKBoXcrOK4lxLM3qi'
          }
        }
      );

      if (status === 200) {
        this.setState({
          ...this.state,
          has_errors: false,
          is_loading: false,
          success_message: data.text[this.props.currentLanguage],
          is_submitted: true
        });
      } else {
        throw new Error('Something went wrong...');
      }
    } catch (err) {
      this.setState({
        ...this.state,
        has_errors: true,
        is_loading: false,
        is_submitted: false
      });
    }
  }

  resetForm(e) {
    e.preventDefault();
    // Reset form fields
    this.formElement.reset();
    // Reset state to initial
    this.setState({
      data: {},
      has_errors: false,
      is_loading: false,
      is_submitted: false
    });
  }

  render() {
    const { data } = this.props;

    return (
      <div className="contact-form subpage-section">
        <h2 className="section-title is-centered">{data.title}</h2>
        <h3 className="section-subtitle is-centered has-padding">
          {data.subtitle}
        </h3>
        <Container>
          <form
            ref={(form_elem) => (this.formElement = form_elem)}
            id="contact-form"
            onSubmit={this.handleSubmit.bind(this)}
          >
            <Columns className="is-multiline">
              {data.form_fields.map((field, i) => {
                switch (field.element) {
                  case 'input': {
                    return (
                      <InputElement
                        type={field.type}
                        name={field.name}
                        onChange={this.handleInputChange.bind(this)}
                        label={field.label}
                        placeholder={field.placeholder}
                        icon={field.icon}
                        full_width={field.full_width}
                        key={i}
                      />
                    );
                  }

                  case 'textarea': {
                    return (
                      <TextareaElement
                        name={field.name}
                        onChange={this.handleInputChange.bind(this)}
                        label={field.label}
                        placeholder={field.placeholder}
                        key={i}
                      />
                    );
                  }

                  default: {
                    return null;
                  }
                }
              })}
            </Columns>
            {this.state.is_submitted ? (
              <SuccessMessage
                onClick={this.resetForm.bind(this)}
                message={this.state.success_message}
                reset_text={data.reset_text}
              />
            ) : (
              <div
                className={`control cta-container${
                  this.state.is_submitted ? ' is-submitted' : ''
                }`}
              >
                <button
                  type="submit"
                  id="submit-btn"
                  className={`button is-primary${
                    this.state.is_loading ? ' is-loading' : ''
                  }`}
                >
                  {data.submit_btn.btn_text}
                </button>
              </div>
            )}
          </form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentLanguage: currentLanguageCodeSelector
});

export default connect(mapStateToProps)(ContactForm);
