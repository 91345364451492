import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

// Fake database EN
import globals from '../../content/globals.json';
import services from '../../content/services.json';
import contact from '../../content/contact.json';
import blog from '../../content/blog.json';
import how_it_works from '../../content/how_it_works.json';

import { MainHero } from '../../components/Hero';

import IntroCard from './IntroCard';
import ServicesSection from './ServicesSection';
import ContactSection from './ContactSection';
import BlogSection from './BlogSection';
import QuoteSection from './QuoteSection';
import HowItWorks from './HowItWorks';

class Home extends Component {
  constructor(props) {
    super(props);
    this.setTitle = this.setTitle.bind(this);
    this.setPageData = this.setPageData.bind(this);
    this.state = {
      services_data: services[this.props.currentLanguage],
      contact_data: contact[this.props.currentLanguage],
      blog_data: blog[this.props.currentLanguage],
      quote_data: globals[this.props.currentLanguage].home_page_quote,
      how_it_works_data: how_it_works[this.props.currentLanguage]
    };
  }

  setTitle() {
    const title = globals[this.props.currentLanguage].hero.title.replace(
      /[^A-Za-z0-9_@/#&+-]*$/g,
      ''
    );
    document.title = `${title} | Optifarm`;
    ReactGA.set({ title: document.title, page: document.title });
  }

  setPageData() {
    this.setState({
      ...this.state,
      services_data: services[this.props.currentLanguage],
      contact_data: contact[this.props.currentLanguage],
      blog_data: {
        ...blog[this.props.currentLanguage],
        posts: blog[this.props.currentLanguage].posts
      },
      quote_data: globals[this.props.currentLanguage].home_page_quote,
      how_it_works_data: how_it_works[this.props.currentLanguage]
    });
  }

  componentDidMount() {
    this.setTitle();
    this.setPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      this.setTitle();
      this.setPageData();
    }
  }

  render() {
    return (
      <div>
        <MainHero />
        <IntroCard />
        <ServicesSection data={this.state.services_data} />
        <ContactSection data={this.state.contact_data} />
        <BlogSection data={this.state.blog_data} />
        <QuoteSection data={this.state.quote_data} />
        <HowItWorks data={this.state.how_it_works_data} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.localize).code
});

export default connect(mapStateToProps)(Home);
