import React, { Component } from 'react';

class MapsSection extends Component {
  render() {
    return (
      <iframe
        title="Optifarm location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2754.4155235997778!2d14.172038315724073!3d46.34129697912109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDbCsDIwJzI4LjciTiAxNMKwMTAnMjcuMiJF!5e0!3m2!1sen!2ssi!4v1514496018609"
        width="100%"
        height="300"
        allowFullScreen
      ></iframe>
    );
  }
}

export default MapsSection;
