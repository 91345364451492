import React, { Component } from 'react';

export class InputElement extends Component {
  render() {
    return (
      <div
        className={`field column ${
          this.props.full_width !== true ? 'is-half' : 'is-12'
        }`}
      >
        <label className="label">{this.props.label}</label>
        <div className="control has-icons-left">
          <input
            name={this.props.name}
            onChange={this.props.onChange}
            className="input"
            type={this.props.type}
            placeholder={this.props.placeholder}
            required
          />
          <span className="icon is-small is-left">
            <i className={`fa fa-${this.props.icon}`}></i>
          </span>
        </div>
      </div>
    );
  }
}

export class TextareaElement extends Component {
  render() {
    return (
      <div className="field column is-12">
        <label className="label">{this.props.label}</label>
        <div className="control">
          <textarea
            name={this.props.name}
            onChange={this.props.onChange}
            required
            className="textarea"
            placeholder={this.props.placeholder}
          ></textarea>
        </div>
      </div>
    );
  }
}

export class SuccessMessage extends Component {
  render() {
    return (
      <div className="success-message-container">
        <p className="success-message">{this.props.message}</p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={this.props.onClick} className="is-small is-centered">
          {this.props.reset_text}
        </a>
      </div>
    );
  }
}
