import React from 'react';

import { getBlogPostAuthorLabel, getBlogPostReadingTimeLabel } from './helpers';

/**
 * Component rendering Blog Post Card meta data
 * @see BlogPostCard
 *
 * @param {Object} props
 * @param {Object} props.author
 * @param {string} props.currentLanguage
 * @param {Object} props.meta
 * @returns {JSX.Element}
 */
const BlogPostCardMeta = ({ author, body, currentLanguage }) => (
  <div className="card-meta">
    <span className="post-author">
      {getBlogPostAuthorLabel({ currentLanguage, author })}
    </span>
    •
    <span className="post-reading-time">
      {getBlogPostReadingTimeLabel({ currentLanguage, body })}
    </span>
  </div>
);

export default BlogPostCardMeta;
