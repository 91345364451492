import React from 'react';

const ContactInfoItem = (props) => (
  <div className="contact-info-item">
    <i className={`fas fa-${props.icon}`}></i>
    {props.text}
  </div>
);

export default ContactInfoItem;
