import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Component rendering single Navbar Menu Item
 * @param {Object} props
 * @param {string} props.href
 * @param {string} props.name
 * @param {Function} props.onClick
 * @param {string} props.pathname
 * @returns {JSX.Element}
 */
const NavbarItem = ({ onClick, pathname, href, name }) => {
  return (
    <Link
      onClick={onClick}
      to={href}
      className={`navbar-item${pathname === href ? ' is-active' : ''}`}
    >
      {name}
    </Link>
  );
};

export default NavbarItem;
