import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { Container, LevelItem } from '../Helpers';

class ContactInfoSection extends Component {
  render() {
    const { data } = this.props;
    let keys = {
      en: ['name', 'address', 'location', 'email'],
      si: ['ime podjetja', 'naslov', 'lokacija', 'e-pošta']
    };
    return (
      <div className="subpage-section">
        <Container>
          <nav className="level contact-informations">
            {Object.keys(data).map((key, i) => {
              let formatted_key = keys[this.props.currentLanguage][i];
              return (
                <LevelItem
                  heading={formatted_key}
                  title={data[key]}
                  key={key}
                />
              );
            })}
          </nav>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.localize).code
});

export default connect(mapStateToProps)(ContactInfoSection);
