import React from 'react';
import Logo from '../../logo.svg';
import AltLogo from '../../logo-alt.svg';

export const HomepageLogo = (props) => (
  <img src={Logo} alt="Optifarm" className="company-logo" />
);

export const SubpageLogo = (props) => (
  <img src={AltLogo} alt="Optifarm" className="company-logo" />
);
