import { isCurrentLanguageEn } from '../../../../../utils/helpers/languages';

/**
 * Returns formatted blog post author string
 * @param {Object} options
 * @param {Object} options.author
 * @param {string} options.currentLanguage
 * @returns {string}
 */
export const getBlogPostAuthorLabel = ({ currentLanguage, author }) => {
  if (!author?.name) {
    return '';
  }

  const prefix = isCurrentLanguageEn(currentLanguage) ? 'By' : 'Pripravil';

  return `${prefix}: ${author.name}`;
};

/**
 * Returns formatted blog post reading time string
 * @param {Object} options
 * @param {string} options.currentLanguage
 * @param {Object} options.meta
 * @returns {string}
 */
export const getBlogPostReadingTimeLabel = ({ currentLanguage, body = [] }) => {
  const prefix = isCurrentLanguageEn(currentLanguage) ? '' : 'Čas branja: ';
  const suffix = isCurrentLanguageEn(currentLanguage) ? ' read' : '';

  const estimetedReadingTime = Math.floor(body.length / 3);

  return `${prefix}${estimetedReadingTime}min${suffix}`;
};
