import React from 'react';
import { useSelector } from 'react-redux';

import data from '../../content/globals.json';
import { currentLanguageCodeSelector } from '../../services/selectors/languageSelectors';

import { Container } from '../Helpers';
import { MainBtn, OutlinedBtn } from '../Buttons';

import { HeroBackground } from './HeroBackground';

import './MainHero.scss';

export const MainHero = () => {
  const currentLanguage = useSelector(currentLanguageCodeSelector);
  const content = data[currentLanguage];

  return (
    <section className="hero">
      <HeroBackground
        imageSrc="/images/hero-bg.png"
        imageAlt={content.hero.title}
      />
      <div className="hero-body">
        <Container>
          <h1 className="title">{content.hero.title}</h1>
          <p className="tagline">{content.hero.text}</p>
          <div className="hero-cta-container">
            <MainBtn
              href={content.hero.main_cta.path}
              target="_blank"
              text={content.hero.main_cta.name}
            />
            <OutlinedBtn
              href={content.hero.sec_cta.path}
              text={content.hero.sec_cta.name}
            />
          </div>
        </Container>
      </div>
    </section>
  );
};
