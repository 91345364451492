import React, { Component } from 'react';
import { Columns } from '../../components/Helpers';
import { BigImage } from '../../components/Images';
import { MainBtn } from '../../components/Buttons';

class ContactSection extends Component {
  render() {
    let contact = this.props.data;
    return (
      <section id="contact">
        <Columns>
          <BigImage
            className="floating column is-two-fifths"
            image={{
              src: '/images/contact-section-img.png',
              alt: 'Get in touch with optifarm team'
            }}
          />
          <div className="column section-content">
            <h2 className="section-title">{contact.front_page.title}</h2>
            <h3 className="section-subtitle">{contact.front_page.subtitle}</h3>
            <p className="section-excerpt">{contact.front_page.excerpt}</p>
            <div className="section-cta-container is-centered">
              <MainBtn
                href={contact.front_page.cta.path}
                text={contact.front_page.cta.name}
              />
            </div>
          </div>
        </Columns>
      </section>
    );
  }
}

export default ContactSection;
