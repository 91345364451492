import React from 'react';

/**
 * Component rendering Navbar Separator item
 * @returns {JSX.Element}
 */
const NavbarSeparator = () => (
  <div className="navbar-item seperator">
    <span className="seperator-item">|</span>
  </div>
);

export default NavbarSeparator;
