import React, { Component } from 'react';
import { Container } from '../Helpers';
import { Card } from '../Cards';

class IntroSection extends Component {
  render() {
    let cardImage = {
      src: this.props.data.featured_image,
      alt: this.props.data.intro_section.title
    };
    return (
      <Container>
        <Card
          className="section-intro-card"
          image={cardImage}
          title={this.props.data.intro_section.title}
        >
          {this.props.data.intro_section.text}
        </Card>
      </Container>
    );
  }
}

export default IntroSection;
