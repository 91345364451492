import { isCurrentLanguageSl } from './languages';

/**
 * Returns Back to Home label for given language
 * @function
 * @param {object} currentLanguage
 * @return {string} Back to home label
 */
export const getBackToHomeLabel = (currentLanguage) => {
  const enLabel = 'To home page';
  const slLabel = 'Na domačo stran';

  if (!currentLanguage?.code) {
    return slLabel;
  }

  return isCurrentLanguageSl(currentLanguage.code) ? slLabel : enLabel;
};

/**
 * Returns Back to Blog label for given language
 * @function
 * @param {object} currentLanguage
 * @return {string} Back to blog label
 */
export const getBackToBlogPostsLabel = (currentLanguage) => {
  const enLabel = 'All blog posts';
  const slLabel = 'Vse blog objave';

  if (!currentLanguage?.code) {
    return slLabel;
  }

  return isCurrentLanguageSl(currentLanguage.code) ? slLabel : enLabel;
};
