import { createSelector } from 'reselect';

const getState = (state) => state.localize;

export const allLanguagesSelector = createSelector(
  [getState],
  (state) => state.languages
);

export const currentLanguageSelector = createSelector(
  [allLanguagesSelector],
  (languages) => languages.find((language) => language.active === true)
);

export const currentLanguageCodeSelector = createSelector(
  [currentLanguageSelector],
  (currentLanguage) => currentLanguage.code
);
