import PropTypes from 'prop-types';
import React from 'react';

import { NavbarItem } from './components';

/**
 * Component rendering Navbar Menu Items
 * @param {Object} props
 * @param {Function} props.onLinkClick
 * @param {Object[]} props.pageStructure
 * @returns {JSX.Element}
 */
const NavbarItems = ({ onLinkClick, pageStructure }) => (
  <React.Fragment>
    {pageStructure.map((page) => {
      if (!page['in-menu']) {
        return null;
      }

      return (
        <NavbarItem
          onClick={onLinkClick}
          href={page.pathname}
          name={page.name}
          key={page.pathname}
        />
      );
    })}
  </React.Fragment>
);

NavbarItems.propTypes = {
  onLinkClick: PropTypes.func.isRequired,
  pageStructure: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default NavbarItems;
