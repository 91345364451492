import React, { Component } from 'react';
import Navbar from '../Navbar';

class Header extends Component {
  render() {
    return (
      <header>
        <Navbar className="main-nav-bar navbar is-transparent" />
      </header>
    );
  }
}

export default Header;
