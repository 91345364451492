import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentLanguageCodeSelector } from '../../services/selectors/languageSelectors';
import { isCurrentLanguageEn } from '../../utils/helpers/languages';

import { Container } from '../Helpers';

class SubpagesHero extends Component {
  getSubpageTitle = () => {
    const { data } = this.props;
    return data?.page_title || data?.title;
  };

  hasTitle = () => {
    return Boolean(this.getSubpageTitle());
  };

  renderPunchline = () => {
    const { data } = this.props;

    if (!data?.punchline) {
      return null;
    }

    return <h2 className="subtitle">{data.punchline}</h2>;
  };

  renderPostMeta = () => {
    const { currentLanguage, data } = this.props;

    if (!data?.author) {
      return null;
    }

    return (
      <div className="single-blog-post-meta">
        <span>
          {isCurrentLanguageEn(currentLanguage)
            ? 'Article written by'
            : 'Članek pripravil/a'}
          : {data.author.name}
        </span>
      </div>
    );
  };

  render() {
    const { hero_image } = this.props;

    return (
      <section className="hero subpage-hero">
        <div
          className="hero-bg"
          style={{ backgroundImage: `url(${hero_image})` }}
        >
          <div className="overlay"></div>
        </div>
        <Container>
          <div className="column hero-body">
            {this.hasTitle() && (
              <h1 className="title">{this.getSubpageTitle()}</h1>
            )}
            {this.renderPunchline()}
            {this.renderPostMeta()}
          </div>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentLanguage: currentLanguageCodeSelector
});

export default connect(mapStateToProps)(SubpagesHero);
