import React, { Component } from 'react';
import { Container, Columns } from '../Helpers';

class Section extends Component {
  render() {
    const {
      data: { featured_image, title, subtitle, intro_paragraph, main_paragraph }
    } = this.props;

    return (
      <div className="subpage-section">
        <Container>
          <Columns className="section-columns">
            <div className="section-featured-image column is-two-fifths">
              <img src={featured_image} alt={title} />
            </div>
            <div className="section-content column">
              <h2 className="section-title">{title}</h2>
              <h3 className="section-subtitle">{subtitle}</h3>
              <div className="section-intro-paragraph">
                <div className="intro-paragraph-content">
                  <p>{intro_paragraph}</p>
                </div>
              </div>
              <div className="section-main-paragraph">
                <p>{main_paragraph}</p>
              </div>
            </div>
          </Columns>
        </Container>
      </div>
    );
  }
}

export default Section;
