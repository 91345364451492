import React from 'react';

import { SubpagesHero } from '../../components/Hero';
import { Container } from '../../components/Helpers';

export const PrivacyPolicy = () => {
  return (
    <div>
      <SubpagesHero
        data={{ title: 'Pogoji poslovanja' }}
        hero_image="/images/subpage-hero-bg.jpeg"
      />
      <Container className="content">
        <div style={{ paddingTop: 64, paddingBottom: 64 }}>
          <h1>Optifarm SmartRetail EULA v301019</h1>
          <h3>SPLOŠNI POGOJI - MOBILNA APLIKACIJA</h3>
          <h5>Optifarm SmartRetail</h5>
          <hr />
          <h2>SPLOŠNI POGOJI</h2>
          <p>
            Ti splošni pogoji poslovanja (splošni pogoji) določajo pogoje
            poslovanja, pravice in obveznosti med podjetjem Optifarm d.o.o.,
            Gradnikova cesta 85, 4240 Radovljica, matična številka: 7015933000,
            davčna številka: SI41125029 (v nadaljevanju Optifarm d.o.o.) in vami
            (v nadaljevanju: uporabnik, naročnik, vi). Za uporabo naših storitev
            morate biti stari vsaj 18 let. Pred uporabo mobilne aplikacije
            Optifarm SmartRetail in storitev, ki so predmet teh splošnih pogojev
            uporabe, preberite spodaj navedene splošne pogoje, ki veljajo za
            vsakogar, ki dostopa do našege mobilne aplikacija Optifarm
            SmartRetail (skupaj: storitve). Z uporabo naših storitev potrjujete,
            da se strinjate s temi splošnimi pogoji ter hkrati izjavljate in
            jamčite, da ste stari vsaj 18 let. V kolikor se s splošnimi pogoji
            ne strinjate, ne uporabljajte naših storitev.
          </p>
          <hr />
          <h3>1. STORITVE</h3>
          <p>
            Optifarm SmartRetail nudi komunikacijsko platformo med končnimi
            kupci in različnimi ponudniki, ki ponujajo nakup prehrambenih
            izdelkov preko mobilne aplikacije (v nadaljnjem besedilu:
            ponudnik/i). Preko naše mobilne aplikacije lahko izdelke različnih
            ponudnikov vključenih v mobilno aplikacijo naročite in plačate v
            skladu z določbami teh splošnih pogojev ter jih prevzamete na
            izbrani prevzemni točki ponudnika (npr. lokalni trgovini) v časovnem
            okviru navedenem ob naročilu. Optifarm SmartRetail tako skrbi za
            posredovanje mobilnega naročila od uporabnika mobilne aplikacije
            Optifarm SmartRetail do ponudnika za prevzem prehrambenih izdelkov
            na izbrani prevzemni točki ponudnika (v nadaljnem besedilu:
            prevzemna točka/e). Vsak ponudnik ima lahko eno ali več prevzemnih
            točk.
          </p>
          <p>
            Optifarm SmartRetail kupcem ponuja mobilno naročilno platformo, ki
            nastopa kot posrednik med končnimi kupci (uporabniki) in ponudniki
            za prevzem prehrambenih izdelkov na izbrani prevzemni točki.
            Optifarm SmartRetail ne ponuja lastnih izdelkov preko naročilne
            platforme in nikoli ne postane pogodbenik glede na oddana naročila
            med ponudniki za prevzem prehrambenih izdelkov na izbrani prevzemni
            točki in končnimi kupci - uporabniki.
          </p>
          <hr />
          <h3>2. REGISTRACIJA</h3>
          <p>
            Za uporabo naših storitev in posledično za prevzem prehrambenih
            izdelkov na prevzemni točki se mora uporabnik predhodno
            registrirati. Registracija uporabnikov poteka preko spletnega
            vmesnika v katerega uporabnik vnese svoje podatke. Z registracijo
            obiskovalec postane uporabnik, pridobi pravico do nakupa - oddaje
            naročila in avtomatsko sprejme te pogoje ter soglaša z načinom
            varovanja, ravnanja in posredovanja osebnih podatkov, na način, ki
            je opisan v členu Politika zasebnosti.
          </p>
          <p>
            Vsak uporabnik prejme ob registraciji na naslov spletne pošte, ki ga
            navede, aktivacijsko povezavo za aktivacijo registracije. Uporabnik
            postane registriran in lahko prične z nakupovanjem po aktivaciji
            naslova spletne pošte. Ob registraciji se za uporabnika Optifarm
            SmartRetail ustvari uporabniški račun, ki je sestavljen iz
            uporabniškega imena in gesla in uporabniku omogoča dostop do za
            njega dostopnih delov mobilne aplikacije.
          </p>
          <p>
            Uporabnik je izključno odgovoren za zaupno vodenje računa in
            varovanjem svojega gesla pred nepooblaščenim dostopom tretjih oseb
            in Optifarm SmartRetail ne prevzema nobene odgovornosti za morebitne
            zlorabe uporabniškega računa. Uporabnik se zaveda in sprejema
            posledice, ki lahko nastanejo, če izbere geslo, ki ga lahko drug
            uporabnik enostavno ugane ali se ne odjavi iz mobilne aplikacije, do
            katerega imajo dostop tudi drugi. Uporabnik ne sme svojega gesla
            deliti z nikomer. Uporabnik je odgovoren za vse dostope preko
            njegovega računa.
          </p>
          <p>
            Z registracijo uporabnik potrjuje, da je posredoval resnične, točne
            in popolne osebne podatke. Izdajanje za tretjo osebo oz. vpisovanje
            podatkov tretje osebe brez njenega dovoljenja je prepovedano.
            Morebitne kršitve ali zlorabe identitete se prijavi pristojnim
            organom.
          </p>
          <hr />
          <h3>3. ODDAJA IN PREKLIC NAROČILA</h3>
          <p>
            Oddano naročilo predstavlja potrditev naročila za prevzem izbranih
            prehrambenih izdelkov v trgovini.
          </p>
          <p>
            Sprememba naročila ali preklic prevzema izbranih izdelkov v trgovini
            po uspešni oddaji naročila preko našega portala ni mogoč. Optifarm
            SmartRetail ne prevzema nobene odgovornosti glede možnosti preklica
            naročila in izrecno poudarja, da je to v izključni diskreciji
            ponudnika prehrambenih izdelkov. Ponudnik je dolžan v roku, ki ga
            naročniku potrdi, prehrambene izdelke tudi pripraviti za prevzem na
            prevzemni točki, pri čemer Optifarm SmartRetail za to ne jamči in ne
            prevzema nobene odgovornosti. V izrednih primerih, ko zaradi kakšnih
            okoliščin naročila ni mogoče pripraviti za prevzem na prevzemni
            točki v določenem času, je ponudnik dolžan o tem naročnika tudi
            obvestiti.
          </p>
          <p>
            Izrecno priznavate in razumete, da je Optifarm SmartRetail zgolj
            posrednik med uporabnikom in ponudnikom, ter da je izpolnitev
            naročila in jamčevanje za izpolnitev izključna obveznost ponudnika
            oz. da Optifarm SmartRetail za izpolnitev naročila ne jamči.
          </p>
          <hr />
          <h3>4. CENE</h3>
          <p>
            Vse cene na naši mobilni aplikaciji so navedene v EUR in vključujejo
            davek na dodano vrednost (DDV), razen če ni drugače navedeno. Na
            mobilni aplikaciji so navedene dokončne cene. Cene se lahko
            spremenijo brez predhodnega obvestila.
          </p>
          <p>
            Naša mobilna aplikacija vsebuje večje število prehrambenih izdelkov,
            zato je vedno mogoče, da imajo nekateri navedeno napačno ceno ali
            naziv Naša mobilna aplikacija vsebuje večje število prehrambenih
            izdelkov, zato je vedno mogoče, da imajo nekateri navedeno napačno
            ceno ali naziv oz., da se je cena pri ponudniku prehrambenih
            izdelkov spremenila. Trudimo se, da je napak čim manj, toda ne
            zagotavljamo pravilnosti in nismo obvezani do nikakršnega
            nadomestila v zvezi z nepravilno ceno oziroma drugimi
            nepravilnostmi.
          </p>
          <hr />
          <h3>5. NAČIN PLAČILA</h3>
          <p>
            Sprejeta plačilna sredstva za plačilo naročil oddanih preko naše
            mobilne aplikacije so: kartično spletno mobilno plačilo z Viso,
            Mastercardom in Maestro.
            <ul>
              <li>
                Kartična mobilna plačila z Visa, Mastercard in Maestro: Katrična
                plačila procesiramo preko storitve Braintree.
              </li>
            </ul>
          </p>
          <p>
            Potrdilo o naročilu je v elektronski obliki shranjeno na strežniku
            upravljavca mobilne aplikacije. Račun za opravljeno storitev
            prejmete ob naročilu na naslov spletne pošte, ki ste jo navedli ob
            registraciji. V primeru plačila s plačilnimi ali kreditnimi
            karticami, jamčite da ste vi oz. plačnik (podatki v računu
            uporabnika) ista oseba ali organizacija, ki je lastnik kreditne
            kartice.
          </p>
          <p>
            {' '}
            Po potrditvi prejema naročila ni možna sprememba vsebine naročila
            oz. končnega zneska naročila, razen izjemoma z odobritvijo Optifarm
            SmartRetail. Mobilno plačilo preko bančnih kartic se izvede direktno
            na račun podjetja Optifarm d.o.o. V primeru reklamacije ali
            povračila denarja se obrnite na našo podporno službo preko email
            naslova support@optifarm.net, najkasneje v roku 48 po oddaji
            naročila. V kolikor bo ugotovljena napaka z naše strani ali strani
            ponudnika, vam bomo povrnili stroške naročila. V primeru, da
            ponudnik ob prejemu naročila zavrne ali prekliče vaše naročilo, bo
            transakcija prekinjena, sredstva pa vrnjena na vaš račun. Povračilo
            sredstev je izjemoma mogoče tudi v primeru napak pri realizaciji
            naročila, kar nam sporočite na naslov support@optifarm.net.
          </p>
          <hr />
          <h3>6. DOVOLJENA UPORABA STORITEV</h3>
          <p>
            Naše storitve lahko uporabljaje v skladu s temi pogoji uporabe in
            skladno z vsakokratno veljavno zakonodajo. Izjavljate in jamčite, da
            boste naše storitve uporabljali v skladu z zakonodajo in z uporabo
            storitev ne boste kršili nobenih pogodbenih obveznosti,
            intelektualne lastnine ali zasebnosti tretjih strank, kakor tudi, da
            ste osebno odgovorni za vsa dejanja glede uporabe storitev.
          </p>
          <p>
            Poleg zgoraj navedenega, izjavljate in jamčite, da ne boste:
            <ul>
              <li>
                uporabljali naših storitev na kakršen koli način, ki bi
                negativno vplival na druge uporabnike oz. ki bi lahko škodil,
                onemogočil ali prekomerno obremenil funkcije naših storitev;
              </li>
              <li>
                storitev ne boste uporabljali za kakršen koli nezakonit ali
                prepovedan namen;
              </li>
              <li>
                uporabljali robotov, pajkov, scraperja ali drugega avtomatskega
                programa za dostop do naših storitev;
              </li>
              <li>
                z vdiranjem, iskanjem gesel ali s katerimi koli drugimi
                sredstvi, poskušali pridobiti nepooblaščene dostopa do katere
                koli storitve, drugih računov, računalniških sistemov ali
                omrežij, ki imajo vzpostavljeno povezavo z našo storitvijo;
              </li>
              <li>
                nezakonito dostopali do naših storitev oz. poskušali pridobiti
                vsebino, gradivo, informacije preko naših storitev;
              </li>
              <li>
                storitev uporabljali na način, s katerim bi kršili pravice
                tretjih oseb, vključno z zavestnim škodovanjem osebi ali pravni
                osebi;
              </li>
              <li>posredovali netočne, napačne ali zavajajoče informacije;</li>
              <li>
                vzpodbujali tretjih oseb na izvedbo katerakoli izmed zgoraj
                navedenih aktivnosti.
              </li>
            </ul>
          </p>
          <p>
            Za zagotovitev varnosti transakcije in poslovanje, si pridržujemo
            pravico zahtevati od vas dokazila, ki izkazujejo vašo identiteto.
            Optifarm SmartRetail si pridržuje pravico, da kadar koli razkrije
            kakršne koli podatke, katerih razkritje je potrebno, da bi izpolnil
            kakršno koli upoštevano zakonodajo, predpis, zakonski postopek ali
            vladno zahtevo ali da v celoti ali delno uredi katere koli podatke
            ali gradivo, zavrne njihovo objavo ali jih odstrani, kjer je to
            razumno utemeljeno.
          </p>
          <hr />
          <h3>7. POVEZAVE NA DRUGE SPLETNE STRANI</h3>
          <p>
            Naše mobilna aplikacija lahko vsebuje povezave in kazalce na druge
            Spletne strani World Wide Web in njihove vire (Povezane strani). S
            tem ko boste dostopali do Povezanih strani boste zapustili našo
            mobilno aplikacijo in storitve. Povezane strani niso pod našim
            upravljanjem, zato Optifarm SmartRetail v nobenem primeru ne
            odgovarja za vsebino teh spletnih strani in za povezave do drugih
            spletnih strani, ki jih Povezane strani vsebujejo. Za Povezane
            strani veljajo njihovi splošni pogoji uporabe. Optifarm SmartRetail
            ima na mobilni aplikaciji oz. v okviru svojih storitev povezave do
            strani tretjih le kot pomoč, zato navajanje spletnih povezav na naši
            mobilni aplikaciji ne predstavlja nobenega priporočila, odobritve
            ali potrditve s strani Optifarm SmartRetail.
          </p>
          <p>
            Optifarm SmartRetail ne predstavlja ali potrjuje, ter izrecno
            zavrača odgovornost za vsakršen nasvet, mnenje, izjavo ali druge
            informacije, prikazane in razširjene preko katere koli Povezane
            strani. S tem soglašate, da je vsakršno zanašanje na mnenje, nasvet,
            informacije prikazane na - ali drugače dostopne na - Povezani strani
            predmet vaše lastne odgovornosti.
          </p>
          <hr />
          <h3>8. POVRATNE INFORMACIJE</h3>
          <p>
            Povratne informacije ki nam jih dajete v povezavi z uporabo naših
            storitev (predloge, izboljšave, ideje, komentarje, vprašanja,
            predložitev materiala) lahko uporabimo za izboljšave/spremembe naših
            storitev oz. mobilne aplikacije brez kakršnih koli omejitev ali
            obveznosti plačila. S tem, ko nam dajete Povratne informacije, bomo
            mi postali izključni lastniki Povratnih informacij, vi pa se
            odpovedujete vseh pravic do Povratnih Informacij (vključno z
            materialnimi avtorskimi pravicami). Povratne informacije lahko
            uporabimo za katerikoli namen (vključno za komercialne namene), brez
            obveznosti kakršnekoli plačila. Vaše Povratne informacije so ne
            zaupne, pri čemer jamčite, da Povratne informacije ne kršijo pravic
            tretjih. Optifarm SmartRetail ne odgovarja za morebitno izgubo, ki
            bi jo utrpeli vi ali tretji zaradi uporabe Povratnih informacij.
            Vaše Povratne tretjih. Optifarm SmartRetail ne odgovarja za
            morebitno izgubo, ki bi jo utrpeli vi ali tretji zaradi uporabe
            Povratnih informacij. Vaše Povratne informacije imamo pravico
            razkriti tretjim, prav tako pa si pridržujemo pravico, da vaše
            Povratne informacije kadarkoli odstranimo iz naše mobilne
            aplikacije.
          </p>
          <hr />
          <h3>9. SPREMEMBE POGOJEV UPORABE</h3>
          <p>
            Optifarm SmartRetail si pridružuje pravico, da lahko kadarkoli
            spremeni, dopolni in spremeni Pogoje uporabe. Vaša odgovornost je da
            se seznanite z vsemi morebitnimi spremembami in obiskujete našo
            mobilno aplikacijo periodično. Datum zadnjih pogojev uporabe je
            zapisan v dokumentu. V primeru da postanejo pogoji uporabe za vas
            nesprejemljivi oziroma da se s pogoji uporabe po njihovi spremembi
            ne strinjate, naših storitev in naše mobilne aplikacije ne
            uporabljajte več. Z uporabo naših storitev in mobilne aplikacije
            soglašate s pogoji uporabe na način kot so bili spremenjeni.
          </p>
          <hr />
          <h3>10. PRAVICE INTELEKTUALNE LASTNINE</h3>
          <p>
            Naše storitve in mobilna aplikacija, njihova vsebina in vsa
            pripadajoča ter v njih vsebovana intelektualna lastnina (vključno z
            avtorskimi pravicami, patenti, pravicami do podatkovne baze,
            blagovnimi znamkami in znamkami storitve), bodisi registrirana ali
            neregistrirana, je last Optifarm SmartRetail ali tretjih strank
            Optifarm SmartRetail in Optifarm SmartRetail si pridružuje vse
            pravice, upravičenja in interese do svojih storitev in mobilne
            aplikacije. Vse pravice storitev Optifarm SmartRetail in mobilne
            aplikacije so in ostanejo last Optifarm SmartRetail in/ali last
            tretje stranke. Pravice intelektualne lastnine so vsi patenti,
            znamke, modeli, materialne avtorske pravice, posamezni znaki, slike,
            imena ali kakršna koli kombinaciji znakov, ki omogočajo razlikovanje
            blaga ali storitev enega podjetja od blaga ali storitev drugega
            podjetja in jih je mogoče grafično prikazati ter se lahko skupaj ali
            posamično registrirajo kot znamka, računalniški programi,
            tehnologije, izboljšave, ki obstajajo oz. se bodo razvile na
            področju uporabe storitev. Ime Optifarm SmartRetail in vsa druga
            imena, kakor tudi lastniške oznake storitev in/ali izdelkov Optifarm
            SmartRetail, imenovanih na naši mobilne aplikacije ali v sklopu
            naših storitev so izključno naša znamka ali tretje stranke.
          </p>
          <p>
            Razen če oz. v obsegu kot je to eksplicitno določeno v predmetnih
            pogojih uporabe, z uporabo naših storitev, niste upravičeni do
            nobene licence oz. ne pridobite nobenih pravic. Strinjate se z
            omejitvami uporabe naših intelektualnih pravic, kot je to določeno v
            predmetnih pogojih uporabe.
          </p>
          <p>
            Brez predhodnega pisnega dovoljenja Optifarm SmartRetail so
            prepovedani reproduciranje, distribuiranje, dajanje v najem, dajanje
            na voljo javnosti in druge oblike javne priobčitve, predelava ali
            vsaka druga uporaba tega avtorskega dela ali njegovih delov v
            kakršnemkoli obsegu ali postopku, vključno s shranjevanjem in
            prenašanjem zvočnega zapisa v elektronski obliki, javnega
            predvajanja s fonogrami, radiodifuznega oddajanja, radiodifuzne
            retransmisije in sekundarnega radiodifuznega oddajanja ter dajanja
            na voljo javnosti zvočnega zapisa dela, predelave ali avdiovizualne
            priredbe dela v obliki zvočnega zapisa ter distribuiranja in dajanja
            v najem primerkov dela v obliki zvočnega zapisa.
          </p>
          <hr />
          <h3>11. JAMSTVA IN IZKLJUČITEV ODGOVORNOSTI</h3>
          <p>
            Naše storitve so na voljo take kot so in kot so razpoložljive, brez
            kakršnih koli jamstev. Ne Optifarm SmartRetail ne njegovi
            dobavitelji ali pogodbeniki ne dajejo nobenih posebnih obljub glede
            svojih storitev oz. kakršnihkoli jamstev, razen kot je izrecno
            določeno v teh pogojih ali dodatnih pogojih. Optifarm SmartRetail ne
            daje nobenih izrecnih ali implicitnih zagotovil glede svojih
            storitev in nemotenega delovanja svojih storitev, glede posameznih
            funkcij, ki so na voljo v storitvah, ter glede njihove
            zanesljivosti, razpoložljivosti ali primernosti. Optifarm
            SmartRetail ne zagotavlja, da bodo storitve delovale neprekinjeno
            ali brez napak, ali da bodo napake odpravljene v razumnem roku.
            Optifarm SmartRetail ne daje jamstev glede odsotnosti kršitve pravic
            tretjih in jamstev in glede ustreznosti za določen namen. Vi jamčite
            Optifarm SmartRetail, da so vaše aktivnosti povezane z uporabo naših
            storitev zakonite v tisti jurisdikciji, kjer storitve uporabljate.
          </p>
          <p>
            Ne dajemo nobenih zagotovil, da bo naša mobilna aplikacija ves čas
            dostopena, da bo delovala neprekinjeno in da bo brez napak, da bodo
            napake odpravljene ali da bo mobilna aplikacija ali server, ki
            omogoča dostopnost do mobilne aplikacije, brez virusov in hroščev.
          </p>
          <p>
            Vsebina, informacije in druga gradiva na mobilni aplikaciji lahko
            vsebujejo netočnosti in pravopisne napake. Optifarm SmartRetail ne
            jamči za pravilnost in popolnost teh informacij, niti ne jamči za
            zanesljivost objavljenih informacij. Potrjujete razumevanje, da s
            tem ko se zanašate na pravilnost in natančnost informacij prevzemate
            vso tveganje, za primer če se take informacije izkažejo kot napačne
            in netočne in če vam zaradi tega nastane kakršna koli škoda.
          </p>
          <p>
            Pridružujemo si popolno diskrecijo pri popravljanju in odpravljanju
            takih napak na mobilni aplikaciji, v programski kodi, vsebini
            Optifarm SmartRetail, storitvah, pri cenah in pri vseh drugih
            informacijah, objavljenih na mobilni aplikaciji.
          </p>
          <hr />
          <h3>12. OMEJITEV ODGOVORNOSTI</h3>
          <p>
            V nobenem primeru Optifarm SmartRetail (vključno z direktorji,
            zaposlenimi, dobavitelji in pogodbeniki Optifarm SmartRetail) ni
            odgovorna za kakršno koli škodo (vključno z a ne omejeno na direktno
            škodo, poslednično škodo, izgubo dobička, prihodkov ali podatkov),
            ki je neposredno ali posredno povezana z: (a) uporabo oz.
            nezmožnostjo uporabe storitev (b) neavtorizirane dostope do vašega
            računa (c) napake v izvedbi storitev (d) napake, prekinitve, izbris
            podatkov ali elektornskih sporočil, virusov, zamud pri izpolitvi
            storitev (e) napak ali izgub v postopku storitev (h) kakršnokoli
            škodo, ki izhaja iz tehnologije, vključno z in ne omejeno na, škodo
            za varnostne vdore, viruse, napake, izbrise, prekinitve, prevare,
            zamude pri izvedbi storitev, elektronskih povezav, napak omrežja oz.
            kakršnekoli druge tehnične napake (g) ki vam je nastala zaradi
            ponudnika oz. izdelkov naročenih pri ponudniku.
          </p>
          <p>
            V nobenem primeru skupne odgovornosti Optifarm SmartRetail za škodo,
            izgubo in druge posledice (bodisi gre za pogodbeno škodo,
            odškodninsko tožbo (vključno a ne omejeno na malomarnost) ali
            kakršne koli druge zahtevke) na podlagi teh pogojev ne bo presegla
            sto (100) evrov.
          </p>
          <hr />
          <h3>13. TRAJNA IN ZAČASNA PREKINITEV STORITEV</h3>
          <p>
            Pridržujemo si pravico trajno ali začasno prekinit dostop do naših
            storitev in mobilne aplikacije, brez predhodnega obvestila. Prav
            tako si pridružujemo pravico zbrisati vse informacije in morebitne
            dokumente, ki se nanašajo na vašo uporabo naših storitev in mobilne
            aplikacije, kadarkoli in brez razloga, posebej pa če mislimo da ste:
            (i) kršili predmetne pogoje uporabe; (ii) ste ustvarili tveganje ali
            ste pravno izpostavili Optifarm SmartRetail.
          </p>
          <hr />
          <h3>14.PRAVICA PRIJAVE PRISTOJNIM ORGANOM OZ. OSEBAM</h3>
          <p>
            Uporabnik se nepreklicno strinja, da lahko Optifarm SmartRetail v
            primeru, če sodišče, oblasti ali tretja oseba domnevajo, da nek
            uporabnik na mobilni aplikaciji Optifarm SmartRetail krši pravila,
            ki omenjeni strani škodijo, podatke in informacije, ki so znani o
            uporabniku posreduje sodišču, javnim organom, zlasti organom za
            telekomunikacije ter drugim. Optifarm SmartRetail je upravičena, da
            takšno dejanje prijavi pristojnim organom.
          </p>
          <hr />
          <h3>15. POLITIKA ZASEBNOSTI</h3>
          <p>
            Optifarm SmartRetail spoštuje Vašo zasebnost in se zavezuje k
            varovanju osebnih podatkov vseh obiskovalcev mobilne aplikacije
            Optifarm SmartRetail. Pridobljene podatke bomo, v skladu z Zakonom o
            varstvu osebnih podatkov (ZVOP-1), skrbno varovali in jih brez vaše
            privolitve ne bomo posredovali tretjim osebam oziroma jih uporabili
            v druge namene. Optifarm SmartRetail se zavezuje, da bo po svojih
            najboljših možnih močeh varovala podatke in zasebnost obiskovalcev
            mobilne aplikacije. Da bi preprečili kakršno koli zlorabo
            pridobljenih podatkov, uporabljamo ustrezne tehnične in
            organizacijske postopke za zavarovanje podatkov. Internet je
            navkljub varnostnim ukrepom nepredvidljiv medij, zato Optifarm
            SmartRetail ne more izključiti morebitne zlorabe podatkov.
          </p>
          <p>
            Vsi posredovani osebni podatki uporabnikov so namenjeni izključno
            uporabi na mobilni aplikaciji ter administraciji storitev. Optifarm
            SmartRetail ne odgovarja za točnost podatkov vnesenih s strani
            uporabnikov.
          </p>
          <p>
            Pridobljene podatke bo ponudnik uporabljal ali razkril le z vašim
            privoljenjem oziroma v primeru, da je taka obveznost določena v
            zakonu ali v dobri veri, da je tako ukrepanje potrebno za postopke
            pred sodišči ali drugimi državnimi organi.
          </p>
          <p>
            V kolikor izberete opcijo plačila naročila s plačilnimi karticami,
            za izvedbo plačila upotabljamo zunanjega partnerja Braintree
            (splošni pogoji Braintree, ki veljajo tudi za vas, če se odločite za
            mobilno plačilo, so na voljo tukaj). Podatkov o plačilnih karticah
            ne hranimo. Z izbiro te opcije izjavljate, da se strinjate z
            njihovimi pogoji uporabe. V kolikor se z njihovimi pogoji uporabe ne
            strinjate, izberite drugo opcijo plačila.
          </p>
          <p>
            Z registracijo na mobilni aplikaciji soglašate z načinom varovanja,
            ravnanja in posredovanja vaših osebnih podatkov na način, ki je
            opisan v politiki zasebnosti. Ob izbiri gesla, ki vam omogoča dostop
            do nekaterih delov mobilne aplikacije ste odgovorni za zaupno
            vodenje tega gesla. Svetujemo Vam, da svojega gesla ne delite z
            nikomer.
          </p>
          <h5>Podatki, ki jih obdelujemo</h5>
          <p>
            Obdelujemo naslednje osebne podatke:
            <ul>
              <li>
                identifikacijski in kontaktni podatki: e-poštni naslov, ime,
                priimek in zgoščena vrednost gesla;
              </li>
              <li>
                podatki, potrebni za izvedbo naročila: naslov, telefonska
                številka, opombe za pripravljalce, podatki za izdajo računa,
                vsebina naročenih artiklov;
              </li>
              <li>
                uporabniške nastavitve: izbrani jezik mobilne aplikacije,
                izbrani filtri na seznamih ponudnikov in prehrambenih izdelkov;
              </li>
              <li>
                podatki o mobilnih navadah: način uporabe in navigacije mobilne
                aplikacije, podatki pridobljeni iz piškotkov, tehnični podatki o
                brskalniku in napravi s katero dostopate portal Optifarm
                SmartRetail, kot je IP naslov in z njim povzana lokacija,
                operacijski sistem, različica brskalnika, ločljivost zaslona
                itd.
              </li>
              <li>
                podatki o branju reklamnih sporočil: število odprtja prejetih
                reklamnih sporočil in čas potreben za njihovo odprtje.
              </li>
            </ul>
          </p>
          <h5>Deljenje osebnih podatkov</h5>
          <p>
            V večini primerov obdelujemo vaše podatke za lastne namene kot
            njihov upravljavec. Pri tem vaše podatke povezane z realizacijo
            naročila posredujemo restavracijam in našim partnerjem za
            zagotavljanje plačil. Podatke posredujemo tudi našim obdelovalcem,
            ki jih obdelujejo v skladu z našimi navodili. Z vašim soglasjem
            lahko podatke posredujemo tudi družbenim in oglaševalskim omrežjem
            za prikazovanje oglasov po meri na drugih spletnih straneh. Vse
            opisane osebne podatke obdelujemo mi, kot upravljavec. To pomeni, da
            mi določamo zgoraj opredeljene namene, v katere vaše osebne podatke
            zbiramo in načine obdelave ter odgovarjamo za ustrezno izvajanje
            le-te.
          </p>
          <p>
            Vaše osebne podatke lahko posredujemo tudi drugim, ki imajo prav
            tako funkcijo upravljavca, in sicer:
            <ul>
              <li>
                v zvezi z obdelavo vašega naročila našim partnerjem, ki so
                vključeni v obdelavo naročila in sicer:
                <ul>
                  <li>
                    trgovinam in drugimpartnerjem, ki so vključeni v pripravo
                    naročila
                  </li>
                  <li>
                    pri plačilu s kreditnokartico podjetju, ki upravljaja s
                    plačilnimi sistemi za zagotavljanje mobilnih plačil
                    Braintree (politika zasebnosti)
                  </li>
                </ul>
              </li>
              <li>
                oglaševalskim in družbenim omrežjem, in sicer:
                <ul>
                  <li>
                    Google Ireland Limited, Gordon House, Barrow Street, Dublin
                    4, Irska (politika zasebnosti)
                  </li>
                  <li>
                    Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal
                    Harbour, Dublin 2, D02 X525, Irska (politika zasebnosti)
                  </li>
                </ul>
              </li>
              <li>
                na podlagi vašega soglasja za pošiljanje reklamnih sporočil:
                <ul>
                  <li>
                    Mailchimp, The Rocket Science Group, LLC, Ponce de Leon Ave
                    NE 675, GA 30308, Atlanta (politika zasebnosti)
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <h5>Posredovanje podatkov izven držav EU</h5>
          <p>
            Vaše osebne podatke lahko v nekaterih primerih posredujemo tudi v
            države, ki niso članice Evropskega gospodarskega prostora in v
            katerih ni nujno zagotovljena ustrezna raven varstva osebnih
            podatkov. Vsako tovrstno posredovanje bo izvedeno le v primeru, da
            se dotični prejemnik zaveže, da bo upošteval standardne pogodbene
            določbe izdane s strani Evropske komisije.
          </p>
          <h5>Vaše pravice</h5>
          <p>
            V povezavi z vašimi osebnimi podatki imate številne pravice kot so:
            pravica do dostopa, pregleda, izbrisa in omejitve obdelave, prenosa,
            ugovora in pritožbe. Če želite uveljaviti katero od vaših pravic nam
            pišite na info@optifarm.net.
          </p>
          <h5>Piškotki</h5>
          <p>
            V prijavnem sistemu aplikacije Optifarm SmartRetail se za potrebe
            lažje in enostavnejše prijave beležijo piškotki. Piškoti so majhni
            deli podatkov, ki jih vaš spletni brskalnik preko strežnika shrani
            na trdi disk vaše naprave, z namenom sledljivosti. Piškotki so
            informacija o vašem obisku spletnih strani in sami po sebi ne
            vsebujejo nobenih osebnih identifikacijskih informacij. Vključujejo
            varno identifikacijsko kodo, ki jo uporabljamo, da Vaše osebne
            podatke povežemo z Vami, kadarkoli se ponovno vrnete na našo mobilno
            aplikacijo. Prosimo upoštevajte, da lahko nekatera oglaševalska
            orodja ustvarijo lastne piškotke nad katerimi nimamo nadzora in za
            njih ne prevzemamo nobene odgovornosti.
          </p>
          <p>
            V primeru vprašanj, pripomb ali zahtev v zvezi z našo uporabo vaših
            podatkov, smo za vas dosegljivi preko elektronske pošte
            info@optifarm.net.
          </p>
          <hr />
          <h3>16. KONČNE DOLOČBE</h3>
          <p>
            Celoten dogovor. Ti splošni pogoji urejajo celotno pravno razmerje
            med vami in Optifarm d.o.o., glede uporabe naše mobilne aplikacije
            in naših storitev. V primeru nedosledja med predmetnimi pogoji
            uporabe in morebitno drugo pogodbo, ki ste jo sklenili z Optifarm
            d.o.o., bodo prevladali predmetni pogoji uporabe, razen če bo
            določeno drugače.
          </p>
          <p>
            Brez odpoveda pravici. V primeru da katera izmed pogodbenih strank
            ne izvrši svoje pravice, ki jo ima na podlagi teh splošnih pogojev,
            se tako ravnanje stranke ne šteje kot odpoved pravici ali kot
            odpoved drugim pravicam.
          </p>
          <p>
            Višja sila. Optifarm SmartRetail ne odgovarja za neizpolnitev
            obveznosti po teh splošnih pogojih, če je taka neizpolnitev
            posledica katerega koli vzroka, ki je izven razumnega nadzora
            Optifarm d.o.o., kar med drugim vključuje mehanske, elektronske in
            komunikacijske okvare ali poslabšanja.
          </p>
          <p>
            Neodvisnost določb. Če se katerakoli določba splošnih pogojev izkaže
            kot neizvršljiva ali neveljavna, potem se to določbo omeji ali
            odpravi v najmanjšem možnem obsegu, ki je potreben, da splošni
            pogoji ostanejo v veljavi.
          </p>
          <p>
            Prenos pogojev uporabe. Optifarm d.o.o. lahko prenese ali dodeli
            tretjemu pravice in obveznosti iz splošnih pogojev, brez vašega
            soglasja. Vi ne morete prenesti ali dodeliti tretjemu pravice in
            obveznosti iz splošnih pogopjev, brez soglasja Optifarm d.o.o..
          </p>
          <p>
            Trajanje in prenehanje. Pogoji uporabe začnejo veljati s trenutkom,
            ko vstopite na našo mobilno aplikacijo oz. ko začnete uporabljati
            naše storitve in prenehajo veljati ko prenehate uporabljati naše
            storitve oziroma mobilno aplikacijo.
          </p>
          <p>
            Pristojno pravo in reševanje sporov. Za urejanje in razlago splošnih
            pogojev velja pravo Republike Slovenije. Optifarm d.o.o. spoštuje
            veljavno zakonodajo o varstvu potrošnikov in se po vseh močeh trudi
            izpolnjevati svojo dolžnost. Pritožba se odda prek e-poštnega
            naslova info@optifarm. net. Postopek obravnave pritožbe je zaupen.
            Ponudnik se zaveda, da je bistvena značilnost potrošniškega spora,
            vsaj kar zadeva sodno reševanje, njegova nesorazmernost med
            ekonomsko vrednostjo zahtevka in stroški, ki nastanejo pri reševanju
            samega spora. To je tudi glavna ovira, da potrošnik ne sproži spora
            pred sodiščem. Zato si bo Optifarm d.o.o. prizadeval po svojih
            najboljših močeh, da se morebitni spori rešijo sporazumno.
          </p>
          <p>
            Vsak spor, nesoglasje ali zahtevek, ki izhaja iz pravnih prašanj
            določil tega dogovora, bosta Optifarm d.o. in uporabnik najprej
            reševala z mediacijo v skladu z Mediacijskimi pravili Stalne
            arbitraže pri Gospodarski zbornici Slovenije. Če se mediacija konča
            ali če spor z mediacijo ne bo rešen v roku šestdeset [60] dni od
            začetka mediacije oziroma v drugem roku, za katerega sta se stranki
            pisno dogovorili, bo spor dokončno rešen v arbitražnem postopku v
            skladu z Arbitražnimi pravili Stalne arbitraže pri Gospodarski
            zbornici Slovenije.
          </p>
          <p>
            Če se abitražni postopek konča ali če spor z arbitražnim postopkom
            ne bo rešen v roku šestdeset [60] dni od začetka arbitražnega
            postopka oziroma v drugem roku, za katerega sta se stranki pisno
            dogovorili, bo spor dokončno rešen se za reševanje spora določi
            pristojno sodišče v Radovljici.
          </p>
          <hr />
          <h3>18. KONTAKTI, POVRATNE INFORMACIJE IN PRITOŽBE</h3>
          <p>
            V primeru da nas želite kontaktirati v zvezi s splošnimi pogoji, nam
            prosim pišite na elektronski naslov: info@optifarm.net
          </p>
          <p>Zadnja posodobitev: Oktober 2019</p> <p>©Optifarm SmartRetail</p>
        </div>
      </Container>
    </div>
  );
};
