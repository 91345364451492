import React from 'react';

/**
 * Component rendering Mobile Menu Close button
 * @param {Object} props
 * @param {Function} props.onClick
 * @returns {JSX.Element}
 */
const NavbarMobileMenuCloseButton = ({ onClick }) => (
  <div className="navbar-item is-hidden-desktop close-btn-container">
    <span onClick={onClick} className="close-menu-btn">
      <span></span>
      <span></span>
    </span>
  </div>
);

export default NavbarMobileMenuCloseButton;
