import PropTypes from 'prop-types';
import React from 'react';

/**
 * Component rendering Mobile Menu toggle button
 * @param {Object} props
 * @param {Function} props.onClick
 * @returns {JSX.Element}
 */
const NavbarMobileMenuButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="button navbar-burger"
    data-target="navMenu"
  >
    <span></span>
    <span></span>
    <span></span>
  </button>
);

NavbarMobileMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default NavbarMobileMenuButton;
