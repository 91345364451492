import React from 'react';

/**
 * Component rendering Sanity Table block content type
 * @param {Object} props
 * @param {Object} props.node
 * @returns {JSX.Element}
 */
const SanityTable = ({ node }) => {
  const { rows } = node;

  if (!rows || !rows.length) {
    return null;
  }

  const [header, ...restOfRows] = rows;

  return (
    <table>
      <thead>
        <tr>
          {header.cells.map((cell) => (
            <th key={header._key + cell}>{cell}</th>
          ))}
        </tr>
      </thead>

      {restOfRows?.map((row) => (
        <tr key={row._key}>
          {row.cells.map((cell) => (
            <td key={row._key + cell}>{cell}</td>
          ))}
        </tr>
      ))}
    </table>
  );
};

export default SanityTable;
