/**
 * @enum {string} LocalLabel
 */
export const Locale = Object.freeze({
  SI: 'si',
  EN: 'en'
});

/**
 * @enum {string} LocalLabel
 */
export const LocaleLabel = Object.freeze({
  [Locale.SI]: 'SI',
  [Locale.EN]: 'EN'
});
