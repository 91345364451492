import { Locale } from '../enums/languages';

/**
 * Returns true if passed locale is 'en'
 * @param {string} currentLocale
 * @returns {boolean}
 */
export const isCurrentLanguageEn = (currentLocale) => {
  return currentLocale === Locale.EN;
};

/**
 * Returns true if passed locale is 'si'
 * @param {string} currentLocale
 * @returns {boolean}
 */
export const isCurrentLanguageSl = (currentLocale) => {
  return currentLocale === Locale.SI;
};
