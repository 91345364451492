import React, { Component } from 'react';

class IconSection extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="single-service">
        <div className="service-icon">
          <img src={data.icon} alt={data.title} />
        </div>
        <div className="service-content">
          <h2 className="service-title">{data.title}</h2>
          <h4 className="service-lead-text">{data.excerpt}</h4>
          {data.full_text.map((paragraph, i) => {
            return (
              <p key={i} className="service-description">
                {paragraph}
              </p>
            );
          })}
        </div>
      </div>
    );
  }
}

export default IconSection;
