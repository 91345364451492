/**
 * Returns trimmed version of blog post content
 * @function
 * @param {object} blogPost
 * @return {string} Blog post excerpt
 */
export const getBlogPostExcerpt = (blogPost) => {
  const content = blogPost?.excerpt || '';

  if (content.length > 140) {
    let trimmedContent = content.substr(0, 140);
    return `${trimmedContent}...`;
  }

  return content;
};

/**
 * Returns blog posts base URL
 * @function
 * @returns {string} Blog post base URL
 */
export const getBlogPostBaseUrl = () => {
  return '/blog';
};
