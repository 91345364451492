import sanityClient from '@sanity/client';

const client = sanityClient({
  apiVersion: '2021-10-21',
  projectId: 't6voj30x', // find this at manage.sanity.io or in your sanity.json
  dataset: 'production', // this is from those question during 'sanity init'
  useCdn: true
});

const config = client.config();

export { config };
export default client;
