import PropTypes from 'prop-types';
import React from 'react';

import { Locale } from '../../../../utils/enums/languages';

import { NavbarLanguageSwitcherItem } from './components';

const NavbarLanguageSwitcher = (props) => (
  <div className="navbar-item languages">
    <div className="field is-grouped">
      {Object.values(Locale).map((locale) => (
        <NavbarLanguageSwitcherItem key={locale} language={locale} {...props} />
      ))}
    </div>
  </div>
);

NavbarLanguageSwitcher.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  onLanguageSwitch: PropTypes.func.isRequired
};

export default NavbarLanguageSwitcher;
