import React from 'react';

export const BigImage = (props) => (
  <div className={`bigimage-container ${props.className || ''}`}>
    <img src={props.image.src} alt={props.image.alt} />
  </div>
);

export const FullWidthImage = (props) => (
  <img
    src={props.image.src}
    alt={props.image.alt}
    className="full-width-image"
  />
);
