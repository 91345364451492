import React, { Component } from 'react';

import { MainBtn } from '../../components/Buttons';
import { Container, Columns } from '../../components/Helpers';
import ServiceItem from '../../components/Services';

class ServicesSection extends Component {
  render() {
    let services = this.props.data;
    return (
      <section id="services">
        <Container>
          <h2 className="section-title">{services.front_page.title}</h2>
          <p className="section-excerpt">{services.front_page.excerpt}</p>
          <Columns>
            {services.services.map((service, i) => {
              return (
                <ServiceItem
                  key={i}
                  title={service.title}
                  excerpt={service.excerpt}
                  icon={service.icon}
                />
              );
            })}
          </Columns>
          <div className="section-cta-container is-centered">
            <MainBtn
              href={services.front_page.cta.path}
              text={services.front_page.cta.name}
            />
          </div>
        </Container>
      </section>
    );
  }
}

export default ServicesSection;
