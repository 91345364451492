import { getActiveLanguage, setActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Navbar from './Navbar';

const mapStateToProps = ({ router, localize }) => ({
  pathname: router.location.pathname,
  routerState: router,
  currentLanguage: getActiveLanguage(localize).code
});

const mapDispatchToProps = {
  setActiveLanguage
};

const ConnectedNavbar = connect(mapStateToProps, mapDispatchToProps)(Navbar);

export default withRouter(ConnectedNavbar);
