import { connect } from 'react-redux';

import NavbarItem from './NavbarItem';

const mapStateToProps = ({
  router: {
    location: { pathname }
  }
}) => ({
  pathname
});

export default connect(mapStateToProps)(NavbarItem);
