import React from 'react';

export const Container = (props) => (
  <div className={`container ${props.className || ''}`}>{props.children}</div>
);

export const Columns = (props) => (
  <div className={`columns ${props.className || ''}`}>{props.children}</div>
);

export const SectionStarter = (props) => (
  <div className={`section-starter ${props.className || ''}`}></div>
);

export const SectionEnder = (props) => (
  <div className={`section-ender ${props.className || ''}`}></div>
);

export const LevelItem = (props) => (
  <div className="level-item has-text-centered">
    <div>
      <p className="heading">{props.heading}</p>
      <p className="title">{props.title}</p>
    </div>
  </div>
);
