import React, { Component } from 'react';
import { Container } from '../../components/Helpers';

class QuoteSection extends Component {
  render() {
    const { data } = this.props;

    return (
      <section id="home-quote" className="quote-section">
        {data && (
          <img src={data.image} alt={data.text} className="full-width-image" />
        )}
        <Container>
          <h4 className="quote">{data.text}</h4>
        </Container>
      </section>
    );
  }
}

export default QuoteSection;
