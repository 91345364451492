import React, { Component } from 'react';
import { Container } from '../../components/Helpers';
import { HowItWorksCard } from '../../components/Cards';
import { MainBtn } from '../../components/Buttons';

class HowItWorks extends Component {
  render() {
    let data = this.props.data;
    return (
      <section id="how-it-works">
        <Container>
          <h2 className="section-title">{data.title}</h2>
          <h3 className="section-subtitle">{data.subtitle}</h3>
          <div className="steps-container">
            <div className="timeline">
              <div className="timeline-items-container">
                {data.steps.map((step, i) => {
                  return <div key={i} className="timeline-item"></div>;
                })}
              </div>
            </div>
            {data.steps.map((step, i) => {
              return <HowItWorksCard key={i} data={step} num={i} />;
            })}
          </div>
          <div className="section-cta-container is-centered">
            <MainBtn href={data.cta.href} text={data.cta.text} />
          </div>
        </Container>
      </section>
    );
  }
}

export default HowItWorks;
