import * as React from 'react';

export const GooglePlayIcon = (props) => {
  return (
    <svg width="350px" height="390px" viewBox="0 0 349 388" {...props}>
      <title>{'google-play-icon'}</title>
      <defs>
        <linearGradient
          x1="61.014%"
          y1="4.985%"
          x2="26.5%"
          y2="71.906%"
          id="prefix__a"
        >
          <stop stopColor="#00A0FF" offset="0%" />
          <stop stopColor="#00A1FF" offset=".7%" />
          <stop stopColor="#00BEFF" offset="26%" />
          <stop stopColor="#00D2FF" offset="51.2%" />
          <stop stopColor="#00DFFF" offset="76%" />
          <stop stopColor="#00E3FF" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="107.44%"
          y1="50.085%"
          x2="-130.45%"
          y2="50.085%"
          id="prefix__b"
        >
          <stop stopColor="#FFE000" offset="0%" />
          <stop stopColor="#FFBD00" offset="40.9%" />
          <stop stopColor="orange" offset="77.5%" />
          <stop stopColor="#FF9C00" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="86.225%"
          y1="30.946%"
          x2="-50.138%"
          y2="136.053%"
          id="prefix__c"
        >
          <stop stopColor="#FF3A44" offset="0%" />
          <stop stopColor="#C31162" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-18.809%"
          y1="-11.89%"
          x2="42.083%"
          y2="35.024%"
          id="prefix__d"
        >
          <stop stopColor="#32A071" offset="0%" />
          <stop stopColor="#2DA771" offset="6.9%" />
          <stop stopColor="#15CF74" offset="47.6%" />
          <stop stopColor="#06E775" offset="80.1%" />
          <stop stopColor="#00F076" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M7.393 6.998C2.99 11.62.35 18.882.35 28.125v332.967c0 9.243 2.641 16.505 7.043 21.126l1.1 1.1 186.62-186.62v-4.4L8.493 5.678l-1.1 1.32z"
          fill="url(#prefix__a)"
          transform="translate(.204 -.479)"
        />
        <path
          d="M257.173 258.979l-62.06-62.28v-4.401l62.28-62.28 1.32.88 73.724 41.813c21.126 11.884 21.126 31.47 0 43.574L258.713 258.1l-1.54.88z"
          fill="url(#prefix__b)"
          transform="translate(.204 -.479)"
        />
        <path
          d="M258.713 258.099l-63.6-63.6-187.72 187.72c6.822 7.262 18.486 8.362 31.25.88l220.07-125z"
          fill="url(#prefix__c)"
          transform="translate(.204 -.479)"
        />
        <path
          d="M258.713 131.118l-220.07-125c-12.985-7.262-24.428-6.382-31.25.88l187.72 187.72 63.6-63.6z"
          fill="url(#prefix__d)"
          transform="translate(.204 -.479)"
        />
        <path
          d="M257.377 256.3L38.847 380.418c-12.324 7.042-23.108 6.602-30.15.22l-1.1 1.1 1.1 1.1c7.042 6.383 18.046 6.823 30.15-.22l220.07-125-1.54-1.32z"
          fill="#000"
          opacity={0.2}
        />
        <path
          d="M7.597 379.539C3.195 374.917.555 367.655.555 358.412v2.2c0 9.244 2.64 16.506 7.042 21.127l1.1-1.1-1.1-1.1zM332.42 213.606l-75.044 42.693 1.32 1.32 73.724-41.813c10.563-5.942 15.845-13.864 15.845-21.787-.88 7.263-6.162 14.085-15.845 19.587z"
          fill="#000"
          opacity={0.12}
        />
        <path
          d="M38.847 7.62L332.42 174.433c9.463 5.502 14.965 12.324 15.845 19.586 0-7.922-5.282-15.845-15.845-21.787L38.847 5.42C17.72-6.465.555 3.439.555 27.426v2.2C.555 5.64 17.72-4.263 38.847 7.62z"
          fill="#FFF"
          opacity={0.25}
        />
      </g>
    </svg>
  );
};
