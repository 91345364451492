import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

export const MainBtn = ({ onClick, target, text, href, className }) =>
  onClick ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={onClick}
      className={classNames('main-btn button is-primary', {
        [className]: !!className
      })}
    >
      {text}
    </a>
  ) : (
    <Link
      to={{ pathname: href }}
      target={target}
      className={'main-btn button is-primary'}
    >
      {text}
    </Link>
  );

export const OutlinedBtn = ({ href, target, text, type }) => (
  <Link
    to={{ pathname: href }}
    className={classNames('outlined-btn button', { [type]: true })}
    target={target}
  >
    {text}
  </Link>
);
