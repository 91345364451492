import { useSelector } from 'react-redux';

import { currentLanguageCodeSelector } from '../services/selectors/languageSelectors';

const useCurrentLanguage = () => {
  const language = useSelector(currentLanguageCodeSelector);

  return language;
};

export default useCurrentLanguage;
