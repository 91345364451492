import React, { Component } from 'react';
import { Card } from '../../components/Cards';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import {
  GooglePlayIcon,
  AppStoreIcon,
  CloseIcon
} from '../../components/Icons';

import data from '../../content/globals.json';

class IntroCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transform: null,
      content: data[this.props.currentLanguage],
      downloadLinksActive: false
    };
    this.bound_handleScroll = this.handleScroll.bind(this);
    this.setData = this.setData.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  handleScroll(e) {
    const { scrollTop } = e.target.scrollingElement;
    const scrollTransform = Math.floor(scrollTop / 10);
    if (scrollTop / 10 < 40) {
      this.setState({
        transform: { transform: `translateY(${scrollTransform}px)` }
      });
    }
  }

  setData() {
    this.setState({
      ...this.state,
      content: data[this.props.currentLanguage]
    });
  }

  onLinkClick(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      downloadLinksActive: !this.state.downloadLinksActive
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.bound_handleScroll, true);
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      this.setData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.bound_handleScroll, true);
  }

  render() {
    const { intro_card } = this.state.content;
    return (
      <section className="intro-card-container">
        <Card
          style={this.state.transform}
          className="intro-card"
          image={{ src: intro_card.image, alt: intro_card.title }}
          title={intro_card.title}
          onLinkClick={this.onLinkClick}
          overlayActive={this.state.downloadLinksActive}
          link={{ href: intro_card.cta.path, text: intro_card.cta.name }}
          CardOverlayComponent={() => (
            <React.Fragment>
              <div className="close-button" onClick={this.onLinkClick}>
                <CloseIcon />
              </div>
              <div className="columns">
                <div className="column">
                  <a
                    href={intro_card.play_store_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-link"
                  >
                    <GooglePlayIcon />
                    <span>Play Store</span>
                  </a>
                </div>
                <div className="column">
                  <a
                    href={intro_card.app_store_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-link"
                  >
                    <AppStoreIcon lang={this.props.currentLanguage} />
                    <span>App Store</span>
                  </a>
                </div>
              </div>
            </React.Fragment>
          )}
        >
          {intro_card.text}
        </Card>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.localize).code
});

export default connect(mapStateToProps)(IntroCard);
