import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { history } from '../../store';
import PageStructure from '../../content/page_structure.json';

import { Locale } from '../../utils/enums/languages';

import { Container } from '../Helpers';
import { MainBtn } from '../Buttons';

import {
  NavbarItems,
  NavbarLanguageSwitcher,
  NavbarMobileMenuButton,
  NavbarMobileMenuCloseButton,
  NavbarSeparator
} from './components';
import { HomepageLogo } from './Logo';

import './Navbar.scss';
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile_menu_open: false,
      page_structure: [],
      currentLanguage:
        localStorage.getItem('lang') || this.props.currentLanguage
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      page_structure: PageStructure.map((page) => {
        return page[this.props.currentLanguage];
      }),
      currentLanguage:
        localStorage.getItem('lang') || this.props.currentLanguage
    });
  }

  handleMenuBtnClick(e) {
    this.setState({
      ...this.state,
      mobile_menu_open: !this.state.mobile_menu_open
    });
  }

  handleLinkClick(e) {
    this.setState({
      ...this.state,
      mobile_menu_open: false
    });
  }

  handleWebshopLinkClick() {
    window.open('https://webshop.optifarm.net/', '_blank');
  }

  handleLanguageChange(lang) {
    let title;
    PageStructure.forEach((page) => {
      if (page[this.props.currentLanguage].pathname === this.props.pathname) {
        history.push(page[lang].pathname);
        title = page[lang].name;
      }
    });
    this.props.setActiveLanguage(lang);
    localStorage.setItem('lang', lang);
    this.setState({
      ...this.state,
      page_structure: PageStructure.map((page) => page[lang]),
      mobile_menu_open: false
    });
    document.title = `${title} | Optifarm`;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      const lang = this.props.currentLanguage;

      this.setState({
        ...this.state,
        currentLanguage: lang,
        page_structure: PageStructure.map((page) => page[lang])
      });
    }
  }

  render() {
    const { currentLanguage, mobile_menu_open, page_structure } = this.state;
    return (
      <nav
        id="page-nav"
        className={
          this.props.className +
          (this.props.pathname === '/' ? ' is-homepage' : ' is-subpage')
        }
      >
        <Container>
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <HomepageLogo />
            </Link>
            <NavbarMobileMenuButton
              onClick={this.handleMenuBtnClick.bind(this)}
            />
          </div>
          <div className={`navbar-menu${mobile_menu_open ? ' is-active' : ''}`}>
            <NavbarMobileMenuCloseButton
              onClick={this.handleMenuBtnClick.bind(this)}
            />
            <div className="navbar-end">
              <NavbarItems
                onLinkClick={this.handleLinkClick.bind(this)}
                pageStructure={page_structure}
              />
              <NavbarSeparator />
              <NavbarLanguageSwitcher
                currentLanguage={currentLanguage}
                onLanguageSwitch={this.handleLanguageChange.bind(this)}
              />
              <NavbarSeparator />
              <MainBtn
                onClick={this.handleWebshopLinkClick}
                text={
                  currentLanguage === Locale.EN
                    ? 'Webshop ↗'
                    : 'Spletna trgovina ↗'
                }
              />
            </div>
          </div>
        </Container>
      </nav>
    );
  }
}

export default Navbar;
