import imageUrlBuilder from '@sanity/image-url';

import cms from './cms';

const builder = imageUrlBuilder(cms);

/**
 * Returns image URL for given image asset
 * @param {Object} source
 * @param {Number} [width=400]
 * @returns {String}
 */
export const getImageUrl = (source, width = 400) => {
  return builder.image(source).width(width);
};
