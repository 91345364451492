import React from 'react';

export const HeroBackground = (props) => (
  <div className="hero-bg">
    <img
      src={props.imageSrc}
      className="full-width-image"
      alt={props.imageAlt}
    />
    <div className="overlay"></div>
  </div>
);
