import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { Container } from '../../components/Helpers';
import { SubpagesHero } from '../../components/Hero';
import { IntroSection, IconSection } from '../../components/Sections';

import services from '../../content/services.json';

class Services extends Component {
  componentDidMount() {
    let title = services[this.props.currentLanguage].page_title.replace(
      /[^a-zA-Z0-9-_\s]/g,
      ''
    );
    document.title = `${title} | Optifarm`;
    ReactGA.set({ title: document.title, page: document.title });
  }

  render() {
    let services_data = services[this.props.currentLanguage];
    return (
      <div className="services-page">
        <SubpagesHero
          data={services_data}
          hero_image={services_data.featured_image}
        />
        <div className="subpage-main-content">
          {services_data.intro_section ? (
            <IntroSection data={services_data} />
          ) : null}
          <Container className="services-container">
            {services_data.services.map((service, i) => {
              return <IconSection key={i} data={service} />;
            })}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.localize).code
});

export default connect(mapStateToProps)(Services);
